import React from 'react';
import './microbiology-service-list.style.scss';

const typesOfServices = require('../../../jsons/what-we-do.json');

const MicrobiologyServiceList = () => {
    return (
        <div className="section-parent-wrapper">
            <div className="section-wrapper micro-list-wrapper">
                <span className="micro-list-title">Mikrobiološke usluge</span>
                <div className="list-wrapper">
                    <div className="types-list left">
                        {typesOfServices[0].map((type, index) => {
                            return (
                                <div className="type" key={index}>
                                    <div className="checkbox">
                                        <img draggable={false} src={require("../../../assets/elements/icon check.png")}
                                             alt="check"/>
                                    </div>
                                    <div className="type-text">
                                        <p>{type.type}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="types-list right">
                        {typesOfServices[1].map((type, index) => {
                            return (
                                <div className="type" key={index}>
                                    <div className="checkbox">
                                        <img draggable={false} src={require("../../../assets/elements/icon check.png")}
                                             alt="check"/>
                                    </div>
                                    <div className="type-text">
                                        <p>{type.type}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MicrobiologyServiceList;
