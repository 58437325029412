import React from 'react';
import "./homepage.style.scss";
import "animate.css/animate.min.css";
import Header from "../../components/header/header";
import MainSlider from "../../components/main-slider/main-slider";
import OurServices from "../../components/our-services/our-services";
import IconSection from "../../components/icon-section/icon-section";
import AboutUs from "../../components/about-us/about-us";
import Reviews from "../../components/reviews-section/reviews";
import Microbiology from "../../components/microbiology-section/microbiology";
import Maps from "../../components/maps/maps";
import Footer from "../../components/footer/footer";
import LoyaltyCard from "../../components/loyalty-card/loyalty-card";
import MicrobiologyServiceList
    from "../../components/microbiology-section/microbiology-services-list/microbiology-service-list";

const Homepage = () => {
    return (
        <div className="hp-wrapper">
            <Header />
            <MainSlider />
            <IconSection />
            <OurServices />
            <AboutUs />
            <Microbiology />
            <MicrobiologyServiceList />
            <LoyaltyCard />
            <Reviews />
            <Maps />
            <Footer />
        </div>
    );
};

export default Homepage;
