import React from 'react';
import "./reviews.style.scss"
import {AnimationOnScroll} from "react-animation-on-scroll";

const reviews = require("../../jsons/user-reviews.json")

const Reviews = () => {
        return (
            <div className="section-wrapper column">
                <div className="reviews-section-title-wrapper">
                    <AnimationOnScroll
                        animateOnce={true}
                        animateIn="animate__fadeInDown">
                        <span className="reviews-section-title">Naši zadovoljni klijenti</span>
                    </AnimationOnScroll>
                </div>
                <div className="reviews">
                    {reviews.map((review, index) => {
                        return (
                            <div className="user-review" key={index}>
                                <div className="review-section-1">
                                    <div className="rating">
                                        <AnimationOnScroll animateOnce={true}
                                                           delay={200}
                                                           duration={0.15}
                                                           animateIn="animate__jackInTheBox">
                                            <span className="material-symbols-outlined">grade</span>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateOnce={true}
                                                           delay={400}
                                                           duration={0.15}
                                                           animateIn="animate__jackInTheBox">
                                            <span className="material-symbols-outlined">grade</span>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateOnce={true}
                                                           delay={600}
                                                           duration={0.15}
                                                           animateIn="animate__jackInTheBox">
                                            <span className="material-symbols-outlined">grade</span>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateOnce={true}
                                                           delay={800}
                                                           duration={0.15}
                                                           animateIn="animate__jackInTheBox">
                                            <span className="material-symbols-outlined">grade</span>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll animateOnce={true}
                                                           delay={1000}
                                                           duration={0.15}
                                                           animateIn="animate__jackInTheBox">
                                            <span className="material-symbols-outlined">grade</span>
                                        </AnimationOnScroll>
                                    </div>
                                    <div className="user-review-description">
                                        <span className="description-text">{review.review}</span>
                                    </div>
                                </div>
                                <div className="user-review-author">
                                    <span className="author-text">{review.author}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="quote">
                    <p>
                        *Vaše zdravlje je vaša najdragocenija imovina, i mi vam možemo pomoći da ga sačuvate*.
                    </p>
                </div>
            </div>
        );
    }
;

export default Reviews;
