import React, {useEffect, useState} from 'react';
// import {Swiper, SwiperSlide} from "swiper/react";
// import {Autoplay, EffectFade, Pagination} from "swiper";
import "./main-slider.scss"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
// import slide1Img from "../../assets/elements/slide 1.png";
// import slide2Img from "../../assets/elements/Desktop verzija izmena.png";
// import slide3Img from "../../assets/elements/slide 3.png";
// import slide4Img from "../../assets/elements/slide 4.png";
// import slide5ImgHoliday from "../../assets/elements/praznici-radno-vreme.png";
// import slideImg1Mobile from "../../assets/elements/mobile/Asset 6.png";
// import slideImg2Mobile from "../../assets/elements/mobile/Asset 7.png";
// import slideImg3Mobile from "../../assets/elements/mobile/Asset 8.png";
// import slideImg4Mobile from "../../assets/elements/mobile/Asset 9.png";
// import slideImg5MobileHoliday from "../../assets/elements/mobile/praznici-radni-vreme.png";
import mainImg from "../../assets/elements/Asset 65@2x.png";
import FirstCardImg from "../../assets/elements/Asset 66@2x.png";
import SecondCardImg from "../../assets/elements/Asset 67@2x-resized.png";
import ThirdCardImg from "../../assets/elements/Asset 69@2x.png";
import useWindowDimensions from "../../util/windowSizeUtil";

const MainSlider = () => {

    const windowSize = useWindowDimensions();

    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        setShowMobile(window.innerWidth < 768);
    }, [windowSize.width]);

    return (
        <>
            <div className="main-page">
                <div className="main-info">
                    <div className="title">
                        <h2>Brinemo o vašem zdravlju <span>zajedno</span></h2>
                    </div>
                    <div className="text">
                        <h4>Nudimo vam mogućnost očuvanja zdravlja kroz niz laboratorijsko-dijagnostičkih procedura sa
                            najkraćim vremenom izdavanja rezultata.
                        </h4>
                        <p>
                            Briga o vašem zdravlju je naš prioritet i naša misija.
                            Hvala vam što ste izabrali Libra lab plus!
                        </p>
                    </div>
                </div>
                <div className="main-img">
                    <img draggable={false} src={mainImg} alt=""/>
                </div>
            </div>

            <div className="header-cards">
                <div className="header-card">
                    <div className="card-img-main">
                        <img draggable={false} src={FirstCardImg} alt=""/>
                    </div>
                    <div className="card-text">
                        <div className="title">
                            <h2>Patronažna služba</h2>
                        </div>
                        <div className="text">
                            <p>Izlazak i uzorkovanje na terenu, za sve one koji iz objektivnog razloga nisu u
                                mogućnosti da dođu do laboratorije. Garantujemo posvećenost i adekvatnu negu za vas i
                                vaše najbliže.</p>
                        </div>
                    </div>
                </div>
                <div className="header-card">
                    <div className="card-img-main">
                        <img draggable={false} src={SecondCardImg} alt=""/>
                    </div>
                    <div className="card-text">
                        <div className="title">
                            <h2>Konsultacije sa lekarom</h2>
                        </div>
                        <div className="text">
                            <p>Odgovor na vaša pitanja, vezana za laboratorijsko dijagnostičke usluge koje pružamo.</p>
                            <div className="heads-up-text-small">
                                <p><i>*Konsultacije sa lekarom su besplatne </i></p>
                                <p><i>*Zakažite konsultacije putem telefona</i></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-card">
                    <div className="card-img-main">
                        <img draggable={false} src={ThirdCardImg} alt=""/>
                    </div>
                    <div className="card-text">
                        <div className="title">
                            <h2>Brzina izdavanja rezultata</h2>
                        </div>
                        <div className="text">
                            <p>Rezultate vaših analiza možete
                                dobiti istog dana ili najkasnije
                                sutradan, u zavisnosti od vrste
                                analize. Naravno postoje i izuzeci,
                                gde se rezultati specificnih analiza
                                moraju sacekati nekoliko dana.</p>
                        </div>
                    </div>
                </div>
            </div>


            {/*Desktop swiper*/}
            {/*<div className={showMobile ? "hide" : "main-swiper-wrapper"}>*/}
            {/*    <Swiper*/}
            {/*        slidesPerView={1}*/}
            {/*        effect={"fade"}*/}
            {/*        loop={true}*/}
            {/*        speed={750}*/}
            {/*        autoplay={{*/}
            {/*            delay: 3000,*/}
            {/*            stopOnLastSlide: false,*/}
            {/*            pauseOnMouseEnter: false,*/}
            {/*            disableOnInteraction: false*/}
            {/*        }}*/}
            {/*        pagination={{*/}
            {/*            clickable: true*/}
            {/*        }}*/}
            {/*        modules={[Pagination, EffectFade, Autoplay]}*/}
            {/*        className="mySwiper"*/}
            {/*    >*/}
            {/*        <SwiperSlide><img className={showMobile ? "hide" : "swiper-image"} src={slide5ImgHoliday} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className={showMobile ? "hide" : "swiper-image"} src={slide1Img} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className={showMobile ? "hide" : "swiper-image"} src={slide2Img} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className={showMobile ? "hide" : "swiper-image"} src={slide3Img} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className={showMobile ? "hide" : "swiper-image"} src={slide4Img} alt=""/></SwiperSlide>*/}
            {/*    </Swiper>*/}
            {/*</div>*/}

            {/*/!*Mobile swiper*!/*/}
            {/*<div className={showMobile ? "main-swiper-wrapper" : "hide"}>*/}
            {/*    <Swiper*/}
            {/*        slidesPerView={1}*/}
            {/*        effect={"fade"}*/}
            {/*        loop={true}*/}
            {/*        speed={750}*/}
            {/*        autoplay={{*/}
            {/*            delay: 3000,*/}
            {/*            stopOnLastSlide: false,*/}
            {/*            pauseOnMouseEnter: false,*/}
            {/*            disableOnInteraction: false*/}
            {/*        }}*/}
            {/*        pagination={{*/}
            {/*            clickable: true*/}
            {/*        }}*/}
            {/*        modules={[Pagination, EffectFade, Autoplay]}*/}
            {/*        className="mySwiper"*/}
            {/*    >*/}
            {/*        <SwiperSlide><img className="swiper-image" src={slideImg5MobileHoliday} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className="swiper-image" src={slideImg1Mobile} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className="swiper-image" src={slideImg2Mobile} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className="swiper-image" src={slideImg3Mobile} alt=""/></SwiperSlide>*/}
            {/*        <SwiperSlide><img className="swiper-image" src={slideImg4Mobile} alt=""/></SwiperSlide>*/}
            {/*    </Swiper>*/}
            {/*</div>*/}
        </>
    );
};

export default MainSlider;
