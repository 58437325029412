import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../util/windowSizeUtil";
import "./header.style.scss";
import "swiper/css";
import "swiper/css/free-mode";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, FreeMode} from "swiper";

const HeaderActions = () => {

    const navigate = useNavigate();

    const windowSize = useWindowDimensions();

    const [showTrack, setShowTrack] = useState(false);

    const [showHamburger, setShowHamburger] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0});
    }, [])

    const scrollToTopAndGoHome = () => {
        navigate("/");
        window.scrollTo({top: 0});
    }

    const mobileMenuCloseOnClick = () => {
        document.getElementById("menu-check-label").click();
        document.getElementById("ham-svg").classList.remove("active");
    }

    const goToContact = () => {
        window.scrollTo({top: 10000000});
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setShowTrack(window.scrollY >= 50);
        });
    }, []);

    useEffect(() => {
        setShowHamburger(window.innerWidth < 900);
    }, [windowSize.width]);

    return (
        <>
            <div className="main-header-wrapper">
                <div className="header-wrapper">
                    <div className="logo">
                        <a onClick={() => scrollToTopAndGoHome()}>
                            <img src={require("../../assets/elements/Logo header.png")} alt=""/>
                        </a>
                    </div>

                    {/*Hamburger menu*/}
                    <div className={showHamburger ? "hamburger-wrapper" : "hide"}>
                        <input type="checkbox" id="menu-check"/>
                        <label id="menu-check-label" htmlFor="menu-check" className="menu-check-label">
                            <svg className="ham hamRotate ham1" viewBox="0 0 100 100" width="50" id="ham-svg"
                                 onClick={(e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
                                     e.currentTarget.classList.toggle("active");
                                 }}>
                                <path
                                    className="line top"
                                    d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"/>
                                <path
                                    className="line middle"
                                    d="m 30,50 h 40"/>
                                <path
                                    className="line bottom"
                                    d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"/>
                            </svg>
                        </label>

                        <div className="link-navigations-mobile">
                            <a className="link-mobile" href={"#"} onClick={() => {
                                scrollToTopAndGoHome();
                                mobileMenuCloseOnClick();
                            }}
                            >POČETNA</a>

                            <a className="link-mobile" href={"#pricelist"}
                               onClick={() => navigate("/pricelist")}>CENOVNIK</a>

                            <a className="link-mobile" onClick={() => {
                                goToContact();
                                mobileMenuCloseOnClick();
                            }}>KONTAKT</a>
                        </div>

                    </div>

                    {/*Desktop navigation*/}
                    <div className={showHamburger ? "hide" : "navigations-wrapper"}>
                        <div className="link-navigations">
                            <a className="link navigation" onClick={() => scrollToTopAndGoHome()}>POČETNA</a>
                            <a className="link navigation" onClick={() => navigate("/pricelist")}>CENOVNIK</a>
                            <a className="link navigation" onClick={() => {
                                goToContact();
                                mobileMenuCloseOnClick();
                            }}>KONTAKT</a>
                        </div>
                        <div className="social-media-links">
                            <a className="instagram" href="https://www.instagram.com/libralabplus/" target="_blank">
                                <img className="social-icon"
                                     src={require("../../assets/elements/insta icon black.png")}
                                     alt=""/>
                            </a>
                            <a className="facebook" href="https://www.facebook.com/libralabplus" target="_blank">
                                <img className="social-icon"
                                     src={require("../../assets/elements/facebook icon black.png")} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={showTrack ? "hide" : "text-slider"}>
                    <Swiper
                        freeMode={true}
                        loop={true}
                        speed={5000}
                        touchMoveStopPropagation={false}
                        autoplay={{
                            delay: 2000,
                            stopOnLastSlide: false,
                            pauseOnMouseEnter: false,
                            disableOnInteraction: false
                        }}
                        modules={[FreeMode, Autoplay]}
                        className="mySwiper text-slider-swiper"
                    >
                        <SwiperSlide>
                            <span className="text-1">Libra Lab 2, Danila Kiša 14, Pirot | PONEDELJAK - PETAK 7:00-17:00 SUBOTA 8:00 - 14:00 |
                                <img draggable="false" src={require("../../assets/elements/phone icon.png")} alt=""/>
                                010 310 700 | 066 6310701
                            </span>
                        </SwiperSlide>
                        <SwiperSlide>
                            <span className="text-2">Libra Lab 1, Lava Tolstoja bb, Pirot | PONEDELJAK - PETAK 07:00-17:00 SUBOTA 08:00 - 14:00 |
                                <img draggable="false" src={require("../../assets/elements/phone icon.png")} alt=""/>
                                010 310 700 | 066 8310700
                            </span>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default HeaderActions;
