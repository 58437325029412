import React from 'react';
import "./about-us.style.scss";
import {AnimationOnScroll} from "react-animation-on-scroll";
import aboutUsImage from "../../assets/elements/Photo 1.png";
import aboutUsImage2 from "../../assets/elements/photo 2.png";

const AboutUs = () => {
    return (
        <>
            <div id="about-us"></div>
            <div className="section-wrapper" id="abouts-us">
                <div className="section-text">
                    <AnimationOnScroll
                        animateOnce={true}
                        animateIn="animation__animate animate__fadeInDown">
                        <span className="section-title">O NAMA</span>
                    </AnimationOnScroll>
                    <span className="section-description">
                        "Libra lab plus" laboratorija raspolaže savremenom
                        opremom i vrhunskim stručnim kadrom, što sve skupa
                        garantuje brzinu, preciznost i pouzdanost rezultata.
                        Korišćenje kvalitetnih reagenasa i repromaterijala
                        omogućavaju visok kvalitet laboratorijskih analiza kao
                        i da se rezultati ispitivanja dobiju u najkraćem
                        vremenskom roku.
                    </span>
                </div>
                <div className="section-image">
                    <img draggable="false" src={aboutUsImage} alt=""/>
                </div>
            </div>

            <div className="section-wrapper reverse">
                    <div className="section-image">
                        <img draggable="false" className="lab-image" src={aboutUsImage2} alt=""/>
                    </div>
                    <div className="section-text">
                        <AnimationOnScroll
                            animateOnce={true}
                            animateIn="animation__animate animate__fadeInDown">
                        <span className="section-title-long">
                            Uz pomoć <span
                            className="name-span">"Libra lab plus"</span> laboratorijske dijagnostike <br/> moguća je:
                        </span>
                        </AnimationOnScroll>
                        <span className="section-description">
                        <ul>
                            <li>Provera fiziološkog stanja organizma</li>
                            <li>Rano otkrivanje bolesti</li>
                            <li>Pomoć u odabiru i doziranju prave terapije</li>
                            <li>Praćenje toka bolesti i uspeha terapije</li>
                            <li>Unapređenje opšteg zdravstvenog stanja redovnom kontrolom laboratorijskih parametara</li>
                        </ul>
                        </span>
                    </div>
            </div>
        </>
    );
};

export default AboutUs;
