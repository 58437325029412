import React from "react";
import "./actions.style.scss";
import {AnimationOnScroll} from "react-animation-on-scroll";


const Action = () => {

    return (
        <div className="actions-eventWrapper">
            <div className="actions-lab1">
                <div className="lab1-title">
                    <h2>AKCIJE LABORATORIJE LIBRA LAB - BIOHEMIJA</h2>
                </div>
                <div className="lab1-actions-cards">
                    <div className="mini-cards">
                        <div className="cards-wrapper">
                            <div className="card">
                                <AnimationOnScroll animateIn="animate__animated animate__fadeInUp"
                                                   animateOnce={true}
                                                   duration={0.5}
                                >
                                    <div className="card-title">
                                        <h2>AKCIJA 1</h2>
                                    </div>
                                </AnimationOnScroll>
                                <div className="card-wrapper">
                                    <div className="card-body">
                                        <h2>VAĐENJE KRVI</h2>
                                        <h2>HBA1C</h2>
                                        <h2>GLUKOZA</h2>
                                        <h2>INSULIN</h2>
                                        <h2>HOMA INDEX</h2>
                                    </div>
                                    <div className="card-footer">
                                        <AnimationOnScroll
                                            animateIn="animate__animated animate__jackInTheBox"
                                            offset={10}
                                            delay={500}
                                            animateOnce={true}
                                        >
                                            <p>Puna cena: 2300,00 RSD</p>
                                            <p><span>Cena sa popustom: 1100,00 RSD</span></p>
                                        </AnimationOnScroll>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <AnimationOnScroll animateIn="animate__animated animate__fadeInUp"
                                                   animateOnce={true}
                                                   duration={0.5}
                                                   delay={100}
                                >
                                    <div className="card-title">
                                        <h2>AKCIJA 2</h2>
                                    </div>
                                </AnimationOnScroll>
                                <div className="card-wrapper">
                                    <div className="card-body">
                                        <h2>VAĐENJE KRVI</h2>
                                        <h2>OGTT TEST 2 UZORKA</h2>
                                        <h2>+ GLUKOZA</h2>
                                        <h2>INSULIN</h2>
                                        <h2>INSULIN 120 MINUTA</h2>
                                    </div>
                                    <div className="card-footer">
                                        <AnimationOnScroll
                                            animateIn="animate__animated animate__jackInTheBox"
                                            offset={10}
                                            animateOnce={true}
                                            delay={600}
                                        >
                                            <p>Puna cena: 1700,00 RSD</p>
                                            <p><span>Cena sa popustom: 500,00 RSD</span></p>
                                        </AnimationOnScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cards-wrapper">
                            <div className="card">
                                <AnimationOnScroll animateIn="animate__animated animate__fadeInUp"
                                                   animateOnce={true}
                                                   delay={200}
                                                   duration={0.5}
                                >
                                    <div className="card-title">
                                        <h2>AKCIJA 3</h2>
                                    </div>
                                </AnimationOnScroll>
                                <div className="card-wrapper">
                                    <div className="card-body">
                                        <h2>VAĐENJE KRVI</h2>
                                        <h2>KRVNA SLIKA</h2>
                                        <h2>GLUKOZA</h2>
                                        <h2>INSULIN</h2>
                                        <h2>HOMA INDEX</h2>
                                    </div>
                                    <div className="card-footer">

                                        <AnimationOnScroll
                                            animateIn="animate__animated animate__jackInTheBox"
                                            offset={10}
                                            animateOnce={true}
                                            delay={100}
                                        >
                                            <p>Puna cena: 1700,00 RSD</p>
                                            <p><span>Cena sa popustom: 500,00 RSD</span></p>
                                        </AnimationOnScroll>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <AnimationOnScroll animateIn="animate__animated animate__fadeInUp"
                                                   animateOnce={true}
                                                   delay={200}
                                                   duration={0.5}
                                >
                                    <div className="card-title">
                                        <h2>AKCIJA 4</h2>
                                    </div>
                                </AnimationOnScroll>
                                <div className="card-wrapper">
                                    <div className="card-body">
                                        <h2>URINO KULTURA</h2>
                                        <h2>OPŠTI PREGLED URINA</h2>
                                    </div>
                                    <div className="card-footer">
                                        <AnimationOnScroll
                                            animateIn="animate__animated animate__jackInTheBox"
                                            offset={10}
                                            animateOnce={true}
                                            delay={300}
                                        >
                                            <p>Puna cena: 770,00 RSD</p>
                                            <p><span>Cena sa popustom: 500,00 RSD</span></p>
                                        </AnimationOnScroll></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="big-card">
                        <div className="card">
                            <AnimationOnScroll animateIn="animate__animated animate__fadeInUp"
                                               animateOnce={true}
                                               duration={0.5}
                            >
                                <div className="card-title">
                                    <h2>AKCIJA 5</h2>
                                </div>
                            </AnimationOnScroll>
                            <div className="card-wrapper">
                                <div className="card-body">
                                    <h2>VAĐENJE KRVI</h2>
                                    <h2>GLUKOZA</h2>
                                    <h2>KKS</h2>
                                    <h2>OPŠTI PREGLED URINA</h2>
                                    <h2>HOLESTEROL</h2>
                                    <h2>TRIGLICERIDI</h2>
                                    <h2>HDL</h2>
                                    <h2>LDL</h2>
                                    <h2>INDEX ATEROSKLEROZE</h2>
                                    <h2>FAKTOR RIZIKA</h2>
                                    <h2>NON HDL</h2>
                                </div>
                                <div className="card-footer">
                                    <AnimationOnScroll
                                        animateIn="animate__animated animate__jackInTheBox"
                                        offset={10}
                                        animateOnce={true}
                                        delay={900}
                                    >
                                        <p>Puna cena: 1630,00 RSD</p>
                                        <p><span>Cena sa popustom: 1156,00 RSD</span></p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="actions-lab2">
                <div className="lab2-title">
                    <h2>AKCIJE LABORATORIJE LIBRA LAB 2 - MICROBIOLOGIJA</h2>
                </div>
                <div className="tables-wrapper">
                    <div className="lab2-wrapper-table">
                        <div className="title-table">
                            <h2>Akutne i hronične dijareje, stomačne tegobe, tečna stolica sa prisustvom sluzi u njoj.
                                Brza
                                dijagnostika crvenih, virusnih i protozoalnih infekcija, naročito kod dece.</h2>
                        </div>
                        <div className="table">
                            <div className="table-row">
                                <h4>Rota-adeno virus</h4>
                                <div className="price">
                                    <p>Puna cena: 1000,00 RSD</p>
                                    <p><span>Cena sa popustom: 750,00 RSD</span></p>
                                </div>
                            </div>
                            <div className="table-row">
                                <h4>Rota-adeno-noro-astro virus</h4>
                                <div className="price">
                                    <p>Puna cena: 2000,00 RSD</p>
                                    <p><span>Cena sa popustom: 1700,00 RSD</span></p>
                                </div>
                            </div>
                            <div className="table-row">
                                <h4>Triple crypto-giardia-entamoeba</h4>
                                <div className="price">
                                    <p>Puna cena: 1500,00 RSD</p>
                                    <p><span>Cena sa popustom: 1200,00 RSD</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lab2-wrapper-table">
                        <div className="title-table">
                            <h2>Sezona je respiratornih infekcija. Izbegnite posledice strptokokne infekcije ( Reumatske
                                groznice, upale bubrega) brzom dijagnostikom Streptokoka grupe A u grlu i nosu vašeg
                                deteta.</h2>
                        </div>
                        <div className="table">
                            <div className="table-row">
                                <h4>Brzi strepto A test</h4>
                                <div className="price">
                                    <p>Puna cena: 600,00 RSD</p>
                                    <p><span>Cena sa popustom: 498,00 RSD</span></p>
                                </div>
                            </div>
                            <div className="table-row">
                                <h4>Bris grla i nosa (bakt/mik)</h4>
                                <div className="price">
                                    <p>Puna cena: 1200,00 RSD</p>
                                    <p><span>Cena sa popustom: 852,00 RSD</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lab2-wrapper-table">
                        <div className="title-table">
                            <h2>Ako planirate trudnoću, spremate se za vantelesnu oplodnju ili imate tegobe sa
                                genetalnim traktom, pružamo vam brzu i pouzdanu dijagnostiku najčešćih polnih
                                infekcija. Vodite računa o svom reproduktivnom zdravlju!</h2>
                        </div>
                        <div className="table">
                            <div className="table-row">
                                <h4>Chlamydia trachomatis DIF</h4>
                                <div className="price">
                                    <p>Puna cena: 1200,00 RSD</p>
                                    <p><span>Cena sa popustom: 996,00 RSD</span></p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="table-text">
                                    <h4>Vaginalni i cervikalni bris (bakt/mik)</h4>
                                    <h4>Aminski test + DMP (bakt.vaginoza)</h4>
                                    <h4>Trichomonas vaginalis</h4>
                                </div>
                                <div className="price">
                                    <p>Puna cena: 1900,00 RSD</p>
                                    <p><span>Cena sa popustom: 1197,00 RSD</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default Action;


