import React from "react";
import "./footer.style.scss";
import Pin from "../../assets/elements/pin white.png";
import Phone from "../../assets/elements/phone icon.png";
import Email from "../../assets/elements/email icon.png";
import Uniqa from "../../assets/elements/Asset 75@2x.png";
import Dunav from "../../assets/elements/Asset 74@2x.png";
import Insta from "../../assets/elements/icon insta white.png";
import Face from "../../assets/elements/icon fb white.png";
import {AnimationOnScroll} from "react-animation-on-scroll";


const Footer = () => {

    return (
        <div className="footer-wrapper" id="contact">
            <div className="info-wrapper">
                <div className="lab-info-wrapper">
                    <div className="info-first-row">
                        <div className="footer-info-item">
                            <h2>Libra Lab Plus</h2>
                            <p>Biohemija sa hematologijom i imunohemijom</p>
                        </div>
                        <div className="footer-info-item">
                            <div className="info-text">
                                <div className="info-img">
                                    <img draggable={false} src={Pin} alt=""/>
                                </div>
                                <h3>Lava Tolstoja bb, Pirot</h3>
                            </div>

                            <div className="info-text">
                                <div className="info-img">
                                    <img draggable={false} src={Phone} style={{padding: "0 0.5em"}} alt=""/>
                                </div>
                                <div className="phone-number">
                                    <h3>010 310 700</h3>
                                    <h3>066 8310700</h3>
                                </div>
                            </div>
                            <div className="info-text">
                                <div className="info-img">
                                    <img draggable={false} src={Email} alt=""/>
                                </div>
                                <h3>libralabplus@gmail.com</h3>
                            </div>
                        </div>
                        <div className="footer-info-item-row">
                            <div className="footer-info-row-item">
                                <h2>Radno vreme:</h2>
                            </div>
                            <div className="footer-info-row-item">
                                <h3>PONEDELJAK - PETAK </h3>
                                <h3>07:00-17:00</h3>
                                <h3>SUBOTA </h3>
                                <h3>08:00-14:00</h3>
                            </div>
                        </div>
                    </div>
                    <div className="info-second-row">
                        <div className="footer-info-item">
                            <h2>Libra Lab 2</h2>
                            <p>Mikrobiologija sa virusologijom</p>
                        </div>
                        <div className="footer-info-item">
                            <div className="info-text">
                                <div className="info-img">
                                    <img draggable={false} src={Pin} alt=""/>
                                </div>
                                <h3>Danila Kiša 14, Pirot</h3>
                            </div>
                            <div className="info-text">
                                <div className="info-img">
                                    <img draggable={false} src={Phone} style={{padding: "0 0.5em"}} alt=""/>
                                </div>
                                <div className="phone-number">
                                    <h3>010 310 700</h3>
                                    <h3>066 8310701</h3>
                                </div>
                            </div>
                            <div className="info-text">
                                <div className="info-img">
                                    <img draggable={false} src={Email} alt=""/>
                                </div>
                                <h3>micro@libralab.rs</h3>
                            </div>
                        </div>

                        <div className="footer-info-item-row">
                            <div className="footer-info-row-item">
                                <h2>Partneri:</h2>
                            </div>
                            <div className="footer-info-row-item">
                                <div className="partner">
                                    <AnimationOnScroll animateIn="animate__animated  animate__fadeIn"
                                                       animateOnce={true}>
                                        <img draggable={false} src={Uniqa} alt=""/>
                                    </AnimationOnScroll>
                                    <AnimationOnScroll animateIn="animate__animated  animate__fadeIn"
                                                       animateOnce={true}>
                                        <img draggable={false} src={Dunav} alt=""/>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-info">
                {/*TODO: look into this rel='noreferrer' for SEO, so we can later track who comes to our site and from where */}
                <h2>Powered by <a href="https://bump-software.com/" target="_blank" rel="noreferrer">BUMP Software</a>
                </h2>
                <h2> © 2023 Libra Lab, All Rights Reserved</h2>
                <div className="media">
                    <img draggable={false} src={Insta} alt=""/>
                    <h2><a href="https://www.instagram.com/libralabplus/">libralabplus</a></h2>
                    <img draggable={false} src={Face} alt=""/>
                    <h2><a href="https://www.facebook.com/libralabplus/">Libra Lab</a></h2>
                </div>
            </div>
        </div>
    )

}

export default Footer;
