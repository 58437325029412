import React from "react";
import "./maps.style.scss";
import Lab1img1 from "../../assets/elements/photo za mape 1.png";
import Lab1img2 from "../../assets/elements/photo 2 za mape.png";
import Lab1img3 from "../../assets/elements/photo 3 za mape.png";
import Lab1img4 from "../../assets/elements/photo 4 za mape.png";


const Maps = () => {

    return (
        <div className="maps-wrapper">
            <div className="map-and-img-card">
                <div className="images-wrapper">
                    <img draggable={false} src={Lab1img1} alt=""/>
                    <img draggable={false} src={Lab1img2} alt=""/>
                </div>
                <div className="map-wrapper">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2910.55707372556!2d22.57850637667718!3d43.155829484456525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47553d44221dec7d%3A0xf25878a6c82b3884!2sLibra%20Lab!5e0!3m2!1sen!2srs!4v1670859347256!5m2!1sen!2srs"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className="map-and-img-card">
                <div className="images-wrapper">
                    <img draggable={false} src={Lab1img3} alt=""/>
                    <img draggable={false} src={Lab1img4} alt=""/>
                </div>
                <div className="map-wrapper">
                    <iframe draggable={false}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2910.661966257056!2d22.58717847667696!3d43.15362718459743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47553dd45b4f681b%3A0x65f2713524860f02!2sLibra%20Lab%202!5e0!3m2!1sen!2srs!4v1670859301068!5m2!1sen!2srs"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}


export default Maps;
