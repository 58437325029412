import React from 'react';
import 'react-tabs/style/react-tabs.css';
import "./price-list-tabs.style.scss";
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, Typography} from "@mui/material";
import {AnimationOnScroll} from "react-animation-on-scroll";
import Footer from "../footer/footer";
import image from "../../assets/elements/Asset 62@2x.png";

const biochemistryPriceListData = require('../../jsons/biohemija-pricelist.json');
const microbiologyPriceListData = require('../../jsons/mikrobiologija-pricelist.json');


class PriceListTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            selectedItems: []
        };

        this.calculateTotal = this.calculateTotal.bind(this);
        this.clearList = this.clearList.bind(this);
        this.removeFromList = this.removeFromList.bind(this);
    }

    check: boolean;


    calculateTotal(item, itemPrice, checked) {
        let {total} = this.state;
        let {selectedItems} = this.state;

        itemPrice = itemPrice.replace(/\,/g, '');

        if (checked) {
            total += parseInt(itemPrice);
            selectedItems.push(item);

        } else {
            total -= parseInt(itemPrice);
            selectedItems.pop(item);
        }

        this.setState({
            total, selectedItems
        });

    }

    removeFromList = (item, index) => {
        const newItemsList = [...this.state.selectedItems];
        newItemsList.at(index).selected = false;

        item.price = item.price.replace(/\,/g, '');

        let newTotal = this.state.total - item.price;
        newItemsList.splice(index, 1);
        this.setState({total: newTotal, selectedItems: newItemsList});
    }

    clearList() {
        this.state.selectedItems.forEach(item => item.selected = false);
        this.setState({selectedItems: []});
        this.setState({total: 0});
    }


    render() {
        return (
            <div>
                <div className="section-wrapper">
                    <div className="information-wrapper">
                        <div className="text-wrapper">
                            <h2 className="text-title">Libra Lab plus</h2>
                            <h2 className="text-title">Cenovnik</h2>
                            <p className="text-description">Obaveštavamo naše korisnike da su cene usluga u našem
                                cenovniku promenljive u zavisnosti
                                od akcija koje redovno objavljujemo na društvenim mrežama. Za ažurirane informacije o
                                trenutnim akcijama, molimo vas da nas kontaktirate telefonom ili pratite na facebook-u i
                                instagram-u.
                            </p>
                            <p className="text-description">Libra Lab plus laboratorija je posvećena pružanju najbolje
                                vrednosti našim korisnicima.
                                Za detaljan pregled naše ponude i cena, u nastavku je dostupan ažurirani cenovnik.
                                Označavanjem željenih analiza, u crvenom polju pokazaće vam se ukupna cena svih
                                odabranih analiza.
                            </p>
                        </div>
                        <div className="image-wrapper">
                            <img className="" draggable="false" src={image} alt=""/>
                        </div>
                    </div>
                    <div className="tabs-wrapper">
                        <h3 className="alert">Ukupna cena se nalazi na dnu stranice</h3>
                        <div className="price-list-title-wrapper">
                            <svg className="libra-arrow" width="31" height="29" viewBox="0 0 31 29" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.00290599 0.5H0L15.5008 17L31.0001 0.5V6.04888V13.6949L15.5008 29L0.00297709 13.6949L0.00290599 0.5Z"
                                    fill="#202020"/>
                            </svg>
                            <h1 className="price-list-title">Biohemija</h1>
                        </div>
                        {biochemistryPriceListData.map((item, accordionIndex) => {
                            return (
                                <Accordion elevation={1} key={accordionIndex} className="item-accordion">
                                    <AccordionSummary sx={{
                                        backgroundColor: "#a8cf45",
                                        color: "black",
                                        fontWeight: "500",
                                        outline: "2px solid white",
                                        '&.Mui-expanded': {
                                            color: "white", backgroundColor: "#B60606",
                                        }
                                    }}>{item.mainName}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'div'}>
                                            {item.itemList.map((desc, itemIndex) => {
                                                return (
                                                    <div className="item-details" key={itemIndex}>
                                                        <div className="name-code-wrapper">
                                                            <span className="item-name">{desc.name}</span>
                                                            <span className="items-code">{desc.code}</span>
                                                        </div>
                                                        <div className="price-action-wrapper">
                                                            <span className="item-price">{desc.price}.00 RSD</span>
                                                            <div className="action-buttons">
                                                                <Checkbox
                                                                    id="price"
                                                                    onChange={(e) => this.calculateTotal(desc, desc.price, this.check = desc.selected = e.target.checked)}
                                                                    checked={desc.selected}
                                                                    value={desc.selected}
                                                                    className="item-add"
                                                                    sx={{
                                                                        color: "#B60606", '&.Mui-checked': {
                                                                            color: "#B60606",
                                                                        }
                                                                    }}>
                                                                </Checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                        <div className="price-list-title-wrapper">
                            <svg className="libra-arrow" width="31" height="29" viewBox="0 0 31 29" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.00290599 0.5H0L15.5008 17L31.0001 0.5V6.04888V13.6949L15.5008 29L0.00297709 13.6949L0.00290599 0.5Z"
                                    fill="#202020"/>
                            </svg>
                            <h1 className="price-list-title">Mikrobiologija</h1>
                        </div>
                        {microbiologyPriceListData.map((item, accordionIndex) => {
                            return (
                                <Accordion elevation={1} key={accordionIndex} className="item-accordion">
                                    <AccordionSummary sx={{
                                        backgroundColor: "#a8cf45",
                                        color: "black",
                                        fontWeight: "500",
                                        outline: "2px solid white",
                                        '&.Mui-expanded': {
                                            color: "white", backgroundColor: "#B60606",
                                        }
                                    }}>{item.mainName}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'div'}>
                                            {item.itemList.map((desc, itemIndex) => {
                                                return (
                                                    <div className="item-details" key={itemIndex}>
                                                        <div className="name-code-wrapper">
                                                            <span className="item-name">{desc.name}</span>
                                                            {/*<span className="items-code">{desc.code}</span>*/}
                                                        </div>
                                                        <div className="price-action-wrapper">
                                                            <span className="item-price">{desc.price}.00 RSD</span>
                                                            <div className="action-buttons">
                                                                <Checkbox
                                                                    id="price"
                                                                    onChange={(e) => this.calculateTotal(desc, desc.price, this.check = desc.selected = e.target.checked)}
                                                                    checked={desc.selected}
                                                                    value={desc.selected}
                                                                    className="item-add"
                                                                    sx={{
                                                                        color: "#B60606", '&.Mui-checked': {
                                                                            color: "#B60606",
                                                                        }
                                                                    }}>
                                                                </Checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                    <div className="price-total-wrapper">
                        <div className="price-total">
                            <AnimationOnScroll animateIn="animate__fadeInUp"
                                               animateOnce={true}
                                               offset={0}
                            >
                                <span>Ukupna cena</span>
                                <span className="total-price">{this.state.total}.00 RSD</span>
                                <div className="selected-items-list">
                                    <ul className="list">
                                        {this.state.selectedItems.map((item, index) => {
                                            return (
                                                <div className="selected-item-wrapper">
                                                    <li key={index}>• {item.name}
                                                        <button className="remove-button"
                                                                onClick={() => this.removeFromList(item, index, this)}>-
                                                        </button>
                                                    </li>
                                                    <span className="selected-item-price">({item.price}.00 RSD)</span>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>

                                {this.state.selectedItems.length > 0 &&
                                    <button className="clear-button" onClick={() => this.clearList(this)}>Očisti
                                        listu</button>
                                }
                                <span
                                    className="information">Za dodatne informacije o rezervisanju usluga, obratiti se na broj </span>
                                <span className="information" style={{fontSize: "1.5rem"}}>010 310 700</span>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default PriceListTabs;
