import React from 'react';
import "./pricelist.style.scss";
import "animate.css/animate.min.css";
import 'react-tabs/style/react-tabs.css';
import PricelistTabs from "../../components/price-list-tabs/price-list-tabs";
import HeaderPricelist from "../../components/header/header-pricelist";

function Pricelist(props) {
    return (
        <div className="pl-wrapper">
            <HeaderPricelist/>
            <PricelistTabs/>
        </div>
    );
}

export default Pricelist;
