import React from 'react';
import "./microbiology.style.scss";
import {AnimationOnScroll} from "react-animation-on-scroll";

const Microbiology = () => {
        return (
            <>
                <div className="section-wrapper">
                    <div className="section-text">
                        <div className="section-title">
                            <AnimationOnScroll
                                animateOnce={true}
                                animateIn="animate__fadeInDown">
                                <span className="micro-title">Mikrobiologija</span>
                            </AnimationOnScroll>
                        </div>
                        <div className="micro-text-wrapper">
                            <p className="section-description">
                                Mikrobiologija se bavi detekcijom uzročnika zaraznih
                                bolesti, njihovom brzom identefikacijom i predlogom
                                terapije. lshod prenosivih bolesti u velikoj meri zavisi od
                                brzine i kvaliteta njihove dijagnostike.
                            </p>
                            <p className="section-description">
                                Libra Lab laboratorija nudi vam mogućnost očuvanja
                                zdravlja kroz niz laboratorijsko-dijagnostičkih procedura
                                sa najkraćim vremenom izdavanja rezultata vodeći
                                računa o tačnosti i preciznosti istih.
                            </p>
                        </div>
                        <div className="micro-image">
                            <img className="libra-2-logo" src={require('../../assets/elements/Asset 73@2x.png')} draggable={false} alt="micro"/>
                        </div>
                    </div>
                    <div className="section-image">
                        <img src={require('../../assets/elements/Asset 72@2x.png')} draggable={false} className="micro-illustration"
                             alt="microbiology-illustration"/>
                    </div>
                </div>
            </>
        );
    }
;

export default Microbiology;
