import React from 'react';
import {AnimationOnScroll} from "react-animation-on-scroll";
import aboutUsImage3 from "../../assets/elements/kartica lojalnosti.png";

const LoyaltyCard = () => {
    return (
        <div className="section-wrapper">
            <div className="section-text">
                <AnimationOnScroll
                    animateOnce={true}
                    animateIn="animation__animate animate__fadeInDown">
                    <span className="section-title">Kartica lojalnosti</span>
                </AnimationOnScroll>
                <span className="section-description">
                        <p style={{fontWeight: 600}}>Zašto koristiti našu Libra Lab karticu lojalnosti?</p>
                        <p>&nbsp;</p>
                        Uz Libra Lab karticu lojalnosti iskoristite super popuste.
                        Kreirali smo za vas jedinstven program lojalnosti kojim
                        nagrađujemo vašu vernost našoj laboratoriji i uslugama.
                        <p>&nbsp;</p>
                        <p style={{fontWeight: 600}}>Kako postati član kartice lojalnosti?</p>
                        <p>&nbsp;</p>
                        Posetite naše laboratorije i uverite se u efikasnost,
                        preciznost i brzinu a mi ćemo vas nagraditi.
                    </span>
            </div>
            <div className="section-image">
                <img className="gift-cards" draggable="false" src={aboutUsImage3} alt=""/>
            </div>
        </div>
    );
};

export default LoyaltyCard;
