import './App.scss';
import Homepage from "./pages/homepage/homepage";
import Pricelist from "./pages/pricelist/pricelist";
import {Route, Routes} from "react-router-dom";
import Actions from "./pages/actions-page/actions";

function App() {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Homepage/>}/>
                <Route exact path="/pricelist" element={<Pricelist/>}/>
                <Route exact path="/actions" element={<Actions/>}/>
            </Routes>

        </>
    );
}

export default App;
