import React, {useEffect, useState} from "react";
import "./icon-section.style.scss";
import {AnimationOnScroll} from "react-animation-on-scroll";
import useWindowDimensions from "../../util/windowSizeUtil";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectCreative, Pagination} from "swiper";

const serviceTypes = require("../../jsons/service-types.json");

const IconSection = () => {

    const windowSize = useWindowDimensions();

    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        setShowMobile(window.innerWidth < 768);
    }, [windowSize.width]);

    if (windowSize.width > 768) {
        return (
            <div className="section-wrapper column">
                <div className="icon-section-title-wrapper">
                    <AnimationOnScroll
                        animateOnce={true}
                        offset={0}
                        animateIn="animate__fadeInDown">
                        <span className="icon-section-title">Laboratorijska dijagnostika Libra Lab</span>
                    </AnimationOnScroll>
                </div>
                <div className="cards">
                    {serviceTypes.map((serviceType, index) => {
                        return (
                            <div className="card" key={index}>
                                <div className="card-img">
                                    <img src={serviceType.image} draggable={false} alt='service_image'/>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <h2 className="card-title">{serviceType.title}</h2>
                                    </div>
                                    <div>
                                        <p className="card-text">{serviceType.description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (

            <div className="icon-swiper-wrapper">
                <Swiper
                    slidesPerView={1}
                    effect={"creative"}
                    autoplay={{
                        delay: 2000,
                        stopOnLastSlide: false,
                        pauseOnMouseEnter: false,
                        disableOnInteraction: false
                    }}
                    pagination={{
                        "clickable": true
                    }}
                    loop={true}
                    speed={750}
                    creativeEffect={{
                        prev: {
                            shadow: true,
                            translate: [0, 0, -400]
                        },
                        next: {
                            translate: ["100%", 0, 0]
                        }
                    }}
                    modules={[Pagination, Autoplay, EffectCreative]}
                    className="mySwiper"
                >
                    {serviceTypes.map((type, index) => {
                        return (
                            <SwiperSlide className="icon-swiper" key={index}>
                                <div className="image">
                                    <img src={type.image} alt="slide_image"/>
                                </div>
                                <div className="text">
                                    <h2>{type.title}</h2>
                                    <p>{type.description}</p>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

            </div>
        )
    }
}

export default IconSection;
