import React, {useEffect, useState} from 'react';
import "./our-services.style.scss";
import {AnimationOnScroll} from "react-animation-on-scroll";
import serviceImage from "../../assets/elements/Illustration Usluge.png";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../util/windowSizeUtil";


const OurServices = () => {

    const windowSize = useWindowDimensions();

    const navigate = useNavigate();

    const [services, setServices] = useState(false);

    useEffect(() => {
        setServices(window.innerWidth >= 1280);
    }, [windowSize.width]);

    return (
        <div className="section-wrapper" id="services">
            <div className="small-title-wrapper">
                <div className={services ? "hide-title" : "show-small-title"}>
                    <AnimationOnScroll
                        animateOnce={true}
                        animateIn="animation__animate animate__fadeInDown">
                        <span className="small-section-title">Usluge</span>
                    </AnimationOnScroll>
                </div>
            </div>
            <div className="section-image">
                <img src={serviceImage} draggable={false} alt=""/>
            </div>
            <div className="section-text">
                <AnimationOnScroll
                    animateOnce={true}
                    animateIn="animation__animate animate__fadeInDown">
                    <span className={services ? "section-title" : "hide-title"}>Usluge</span>
                </AnimationOnScroll>
                <AnimationOnScroll
                    animateOnce={true}
                    animateIn="animation__animate animate__fadeInDown">
                    <span className="section-description">
                        <p>Poštovani, "Cenovnik" je aplikacija uz pomoć</p>
                        <p>koje odmah možete da izračunate troškove</p>
                        <p>potrebnih laboratorijskih analiza.</p>
                    </span>
                </AnimationOnScroll>

                <div className="button-wrapper">
                    <AnimationOnScroll
                        animateOnce={true}
                        animateIn="animation__animate animate__fadeInDown">
                        <button className="pricelist-button" onClick={() => navigate("/pricelist")}>Cenovnik</button>
                    </AnimationOnScroll>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
