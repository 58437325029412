import React from 'react';
import Footer from "../../components/footer/footer";
import "./actions.style.scss";
import Action from "../../components/actions/actions";
import HeaderActions from "../../components/header/header-actions";

const Actions = () => {
    return (
        <div>
            <HeaderActions/>
            <Action/>
            <Footer />
        </div>
    );
};

export default Actions;
